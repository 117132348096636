<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Companies')" :description="$t('data_count', {count: pagination.totalRecords, type: $t('Companies').toLowerCase()})">
        <block-tools>
          <li>
            <nk-button type="primary" v-on:click="[resetFormData(), addEditForm.modalOpen=true]">
              <nio-icon icon="ni-plus"></nio-icon> {{$t('Add Company')}}
            </nk-button>
          </li>
        </block-tools>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                        <option value="">{{ $t('Bulk Action') }}</option>
                        <option value="email">{{ $t('Send Email') }}</option>
                        <option value="suspend">{{ $t('Suspend') }}</option>
                        <option value="delete">{{ $t('Delete') }}</option>
                      </select>
                    </div>
                    <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">{{ $t('Apply') }}</nk-button>
                          </span>
                      <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}">
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                      <li class="btn-toolbar-sep"></li>
                      <li>
                        <drop-down
                            icon="ni-filter-alt"
                            size="lg"
                            direction="right"
                            :dot="( statusFilter !== -1  ? 'primary':'')"
                            :title="$t('Agents')+' '+$t('Filter')">
                          <template v-slot:body>
                            <row class="gx-6 gy-3">
                              <column default="12" md="6">
                                <label class="overline-title overline-title-alt" for="status_filter">{{ $t('Account status') }}</label>
                                <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                  <option value="-1">{{ $t('All') }}</option>
                                  <option value="1">{{ $t('Active')}}</option>
                                  <option value="0">{{ $t('Disabled') }}</option>
                                </select>
                              </column>
                            </row>
                          </template>
                          <template v-slot:footer>
                            <nk-button type="dark" dim v-on:click="getCompanies(true)">{{ $t('Filter') }}</nk-button>
                            <a href="javascript:;" v-on:click="[statusFilter='',getCompanies(true)]">{{ $t('Reset Filter') }}</a>
                          </template>
                        </drop-down>
                      </li>
                      <li>
                        <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                          <ul class="link-check">
                            <li><span>{{ $t('Order') }}</span></li>
                            <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getCompanies(true)]">{{ $t('ASC') }}</a></li>
                            <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getCompanies(true)]">{{ $t('DESC') }}</a></li>
                          </ul>
                        </drop-down>
                      </li>
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search')+' '+$t('Company').toLowerCase()"
                  :is-active="searchBarActive"
                  @do-search="searchCompanies"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchCompanies('')]"></card-search>
            </div>
            <div class="d-flex py-5 justify-center"  v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <special-table>
                  <table-row is-heading>
                    <table-col has-check-box>
                      <b-check-box id="adv_select_all" v-model="selectAll"></b-check-box>
                    </table-col>
                    <table-col break-point="sm" class="cursor-pointer" v-on:click="doSort('id')">
                      <div class="d-flex align-items-center">
                        <span class="sub-text">ID</span>
                        <template v-if="sort.sortBy === 'id'">
                          <nio-icon class="ml-1" :icon="sort.sortOrder === 'asc' ? 'ni-sort-up-fill' : 'ni-sort-down-fill'"></nio-icon>
                        </template>
                      </div>
                    </table-col>
                    <table-col class="cursor-pointer" v-on:click="doSort('company_name')">
                      <div class="d-flex align-items-center">
                        <span class="sub-text">{{ $t('Company') }}</span>
                        <template v-if="sort.sortBy === 'company_name'">
                          <nio-icon class="ml-1" :icon="sort.sortOrder === 'asc' ? 'ni-sort-up-fill' : 'ni-sort-down-fill'"></nio-icon>
                        </template>
                      </div>
                    </table-col>
                    <table-col break-point="mb">{{ $t('Address') }}</table-col>
                    <table-col break-point="md">{{$t('Counts')}}</table-col>
                    <table-col break-point="lg" class="cursor-pointer" v-on:click="doSort('created_at')">
                      <span>{{ $t('Joined') }}</span>
                      <template v-if="sort.sortBy === 'created_at'">
                        <nio-icon class="ml-1" :icon="sort.sortOrder === 'asc' ? 'ni-sort-up-fill' : 'ni-sort-down-fill'"></nio-icon>
                      </template>
                    </table-col>
                    <table-col break-point="lg">{{ $t('Last seen') }}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="com in companies" :key="'ag_key'+com.id">
                    <table-col has-check-box>
                      <b-check-box :id="'com'+com.id" v-model="com.selected" :key="'com_select'+com.id"></b-check-box>
                    </table-col>
                    <table-col break-point="sm">{{ com.id }}</table-col>
                    <table-col>
                      <user-small-card :user-name="com.company_name" :user-email="com.email" :image="com.dp" :initials="com.initials" :is-online="com.is_online"></user-small-card>
                    </table-col>
                    <table-col break-point="mb">
                      <template v-if="com.city">
                        <span class="tb-lead">{{ com.city+' '+com.post_code }}</span>
                        <span>{{com.province }}</span>
                      </template>
                    </table-col>
                    <table-col break-point="md" class="lh-n medium">
                      <span class="d-block">{{ $t('Advisors') +': '+ com.total_advisors }}</span>
                      <span class="d-block">{{ $t('Leads') +': '+ com.leads_count }}</span>
                      <span class="d-block">{{ $t('Appointments') +': '+ com.appointments_count }}</span>
                    </table-col>
                    <table-col break-point="lg">{{ com.created_at }}</table-col>
                    <table-col break-point="lg">{{ com.last_seen }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <table-action v-if="isDesktop"
                                      :icon="com.user_status === 0 ? 'ni-unlock-fill' : 'ni-lock-fill'" v-on:click="F_changeAccountStatus([com])"></table-action>
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'adminCompanyProfile', params: {id: com.id} }"></list-item>
                            <list-item icon="ni-activity-round" :text="$t('Activities')" :to="{name: 'adminCompanyProfile', params: {id: com.id, tab: 'activities'}}"></list-item>
                            <list-item icon="ni-opt-alt" :text="'Leads '+$t('Limit')" :to="{name: 'adminCompanyProfile', params: {id: com.id, tab: 'e_curring'}}"></list-item>
                            <list-item v-if="!isDesktop"
                                       :icon="com.user_status === 0 ? 'ni-unlock-fill' : 'ni-lock-fill'"
                                       :text="com.user_status === 0 ? $t('Activate'): $t('Suspend')"
                                       v-on:click="F_changeAccountStatus([com])"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > 10">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="10"
                    @paginate="paginationCallback"
                    theme="bootstrap4"
                    :options="{chunk: 5, texts:{count: '||'}}"/>
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <ion-modal
        :is-open="addEditForm.modalOpen"
        @didDismiss="addEditForm.modalOpen=false"
        :css-class="isDesktop ? 'modal-web': ''">
      <i-modal :title="$t(addEditForm.formData.id > 0 ? 'Edit Company' : 'Add Company')" @modal-close="addEditForm.modalOpen=false">
        <form-group>
          <row>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="c_name">{{ $t('Name') }} *</label>
              <b-input type="text" id="c_name" v-model="addEditForm.formData.company_name"/>
            </column>
          </row>
        </form-group>
        <form-group>
          <row>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="c_email">{{ $t('Email') }} *</label>
              <b-input type="text" id="c_email" v-model="addEditForm.formData.email"/>
            </column>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="phone_no">{{ $t('Phone number') }} *</label>
              <b-input type="text" id="phone_no" v-model="addEditForm.formData.phone_number"/>
            </column>
          </row>
        </form-group>
        <form-group>
          <row>
            <column md="6" class="mb-3 mb-md-0">
              <row>
                <column lg="6">
                  <label class="form-label" for="postcode">Postcode *</label>
                  <b-input type="text" id="postcode" placeholder="5542 AW" v-model="addEditForm.formData.post_code"/>
                </column>
                <column lg="6">
                  <label class="form-label" for="house_no">{{ $t('House number') }} *</label>
                  <b-input type="text" id="house_no" v-model="addEditForm.formData.house_no"/>
                </column>
              </row>
            </column>
            <column md="6">
              <label class="form-label" for="lang">{{$t('Language')}}</label>
              <b-select id="lang" v-model="addEditForm.formData.lang">
                <option value="nl">{{$t('Dutch')}}</option>
                <option value="en">{{$t('English')}}</option>
              </b-select>
            </column>
          </row>
        </form-group>
        <form-group>
          <row>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="c_afm">{{ $t('AFM number') }} *</label>
              <b-input type="text" id="c_afm" v-model="addEditForm.formData.afm_number"/>
            </column>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="kvk_number">{{ $t('Chamber of commerce number') }} *</label>
              <b-input type="text" id="kvk_number" v-model="addEditForm.formData.kvk_number"/>
            </column>
          </row>
        </form-group>
        <form-group>
          <row>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="kifid_number">Kifid {{ $t('Number').toLowerCase() }}</label>
              <b-input type="text" id="kifid_number" v-model="addEditForm.formData.kifid_number"/>
            </column>
            <column md="6" class="mb-3 mb-md-0">
              <label class="form-label" for="policy_no">{{ $t('Policy no.') }}</label>
              <b-input type="text" id="policy_no" v-model="addEditForm.formData.policy_no"/>
            </column>
          </row>
        </form-group>
        <template v-slot:footer class="p-5">
          <nk-button type="primary" class="mr-2" v-on:click="addUpdateCompany()">{{ $t(addEditForm.formData.id > 0 ? 'Update' : 'Add') }}</nk-button>
          <a href="javascript:;" class="link link-light" v-on:click="addEditForm.modalOpen=false">{{ $t('Cancel') }}</a>
        </template>
      </i-modal>
    </ion-modal>
  </page-template>
</template>

<script>
import {
  IonModal,
  IonSpinner,
  alertController,
  loadingController,
  toastController,
  isPlatform, onIonViewWillEnter
} from '@ionic/vue';
import {defineComponent, reactive, ref, watch} from 'vue';
import {BlockContent, BlockHead, BlockTools, Column, FormGroup, MainBlock, Row} from "@/@core/layouts";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import {DropDown, DropDownList} from "@core/components";

import Pagination from "v-pagination-3"
import {SpecialTable, TableAction, TableCol, TableRow} from "@core/components/special-table";
import {BCheckBox, BInput, BSelect} from "@core/components/bp-form";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import ListItem from "@core/components/dropdown-list/ListItem";

import {useRouter} from "vue-router"
import {useI18n} from "vue-i18n";
import IModal from "@core/components/ion-modal/IModal";
import useUser from "@core/comp-functions/User"
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    BSelect,
    BInput,
    FormGroup,
    IModal,
    BlockTools,
    ListItem,
    DropDownList,
    TableAction,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    IonModal,
    IonSpinner,
    Pagination,
  },
  setup() {

    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    const router = useRouter()
    const{ changeAccountStatus, deleteUsers } = useUser()

    let searchFilter  = ref('');
    let statusFilter  = ref(-1)
    let order         = ref('desc')
    let sort          = reactive({
      sortBy: '',
      sortOrder: 'asc'
    })

    let loadingData = ref(false)

    let selectAll    = ref(false)

    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })

    let companies = ref([])
    let addEditForm = reactive({
      modalOpen: false,
      formData:{
        afm_number: '',
        company_name: '',
        email: '',
        house_no: '',
        id: 0,
        kifid_number: '',
        kvk_number: '',
        lang: '',
        phone_number: '',
        policy_no: '',
        post_code: '',
      }
    });

    const resetFormData = ()=>{
      for(let x in addEditForm.formData){
        addEditForm.formData[x] = ''
      }
      addEditForm.formData.id = 0
    }

    const addUpdateCompany = async () => {

      let fd = addEditForm.formData
      let email_reg = /^\S+@\S+$/;
      if( !fd.company_name || !fd.post_code || !fd.house_no || !fd.kvk_number || !fd.afm_number || !fd.email){
        toastController.create({color: 'danger', message: i18n.t('errors.please_fill_fields_with_asterisk'), duration:2000, position: 'top'}).then( toast => toast.present())
        return
      }
      else if( !email_reg.test( addEditForm.formData.email)){
        toastController.create({color: 'danger', message: i18n.t('registration.provide_valid_email'), duration:2000, position: 'top'}).then( toast => toast.present())
        return
      }

      let loader = await loadingController.create({message: i18n.t('Please wait...')})
      await loader.present()

      const alertB = await alertController.create({
        header: i18n.t('Error'),
        cssClass: isDesktop ? 'alert-web' : '',
        buttons: [{text: i18n.t('OK'), cssClass: isDesktop ? 'alert-btn-light' : ''}]
      });

      axios.post('/api/admin/company/register', addEditForm.formData)
      .then(r=>{
        if(!r.data.success){
          alertB.message = r.data.message
          alertB.present()
        }
        else{
          addEditForm.modalOpen = false
          let msg = i18n.t('thank_you_messages.company_added_updated')
          if(addEditForm.formData.id < 1){
            msg += "<br>"+i18n.t('account_activation_mail_sent')
            companies.value.unshift(r.data.data)
          }
          else{
            for(let x in companies.value){
              if(parseInt(companies.value[x].id) === parseInt(addEditForm.formData.id)){
                companies.value[x] = r.data.data
              }
            }
          }
          alertController.create({
            header: i18n.t('Success'),
            cssClass: isDesktop ? 'alert-web' : '',
            message: msg,
            buttons:[{text: i18n.t('OK'), cssClass: isDesktop ? 'alert-btn-light' : ''}]
          })
          .then((al) => al.present())
          resetFormData()
        }
      })
      .catch(er=>{
        alertB.message = er.response ? er.response.status+' '+er.response.statusText : i18n.t('errors.general_error')
        alertB.present()
      })
      .then(()=> loader.dismiss());
    }
    const editAgent = (index)=>{
      addEditForm.formData  = Object.assign(addEditForm.formData, companies[index])
      addEditForm.modalOpen = true
    }

    const paginationCallback = async () => {
      await getCompanies()
    }

    let cancelToken = false
    const getCompanies = async (reset) => {

      if(reset){
        pagination.page = 1
      }

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/admin/company/list', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            sort_by: sort.sortBy,
            order: sort.sortOrder,
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            resp = resp.data
            for(let x in resp.data.records){
              resp.data.records[x].selected = false;
            }
            companies.value = resp.data.records
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Companies.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getCompanies(true))

    const searchCompanies = (search) => {
      searchFilter.value = search
    }

    onIonViewWillEnter(()=> getCompanies(true))

    watch(selectAll, (n) => {
      for(let x in companies.value){
        companies.value[x].selected = n
      }
    })

    const F_changeAccountStatus = ( _advisors, action ) => {
      changeAccountStatus(_advisors, 'appointment agent', action)
          .then(res =>{
            for(let y in companies.value){
              if(res.ids.includes(companies.value[y].user_id)){
                companies.value[y].user_status = res.status
              }
            }
          }).catch(()=>{})
    }
    const F_deleteAgents = (users) => {
      deleteUsers(users, 'agent')
      .then(ids=>{
        // Remove the deleted entries from the advisors array
        for(let x in ids){
          for(let y in companies.value){
            if(companies.value[y].user_id === ids[x]){
              companies.value.splice(y,1)
              break;
            }
          }
        }
        // All deleted, no entry left in the advisor array? and previous pages exists
        if( !companies.value.length && pagination.page > 1 ){
          // Deduct from total records value
          pagination.totalRecords -= ids.length
          // Goto previous page
          pagination.page--
          getCompanies(true)
        }
      }).catch(()=>{})
    }

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedRecords = []
      for(let x in companies.value){
        if( companies.value[x].selected){
          selectedRecords.push( companies.value[x])
        }
      }
      if( selectedRecords.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'email' ){
        // TODO
        return false
      }
      else if( bulkSelectedAction.value === 'suspend' ){
        await F_changeAccountStatus(selectedRecords, 'suspend')
      }
      else{
        F_deleteAgents(selectedRecords, 'agent')
      }
    }

    const doSort = (f) => {
      if(sort.sortBy !== f){
        sort.sortOrder = 'asc'
        sort.sortBy = f
      }
      else{
        sort.sortOrder = sort.sortOrder === 'asc' ? 'desc' : 'asc'
      }
      getCompanies(true)
    }

    return {
      addEditForm,
      addUpdateCompany,
      companies,
      doSort,
      editAgent,
      getCompanies,
      resetFormData,

      isDesktop,
      loadingData,
      searchCompanies,

      searchFilter,
      statusFilter,
      order,
      pagination,
      paginationCallback,

      searchBarActive,
      selectAll,

      F_changeAccountStatus,

      bulkSelectedAction,
      bulkAction,

      router,
      sort,
    }
  }
});

</script>
